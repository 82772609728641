import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import kebabCase from "lodash/kebabCase";

// Style Imports
import '../../sass/components/tag-list.scss';

/**
 * A component to display an array list of tags. Used in article listings and pages to display tags associated with an article, as selected by the user in the CMS.
*/

const TagList = ({
  tags,
  label,
  prepend,
  count,
  variant,
}) => {
  const baseClass = 'tag-list';
  const countClass = count ? 'tag-list--count' : '';
  const variantClass = variant ? `tag-list--${variant}` : '';
  const classes = [baseClass, countClass, variantClass].filter(Boolean).join(' ');

  return (
    <nav className={classes} aria-label={label}>
      {prepend && (
        <p className='tag-list__prepend'>{prepend}</p>
      )}
      <ul className='tag-list__list'>
        {tags && tags.map((tag, index) => (
          <li className='tag-list__list-item' key={index}>
            <Link
              className='tag-list__link'
              to={`/articles/tags/${kebabCase(count ? tag.fieldValue : tag)}`}
            >
              {count ? tag.fieldValue : tag}
              {count && (
                 <span className='tag-list__count'>{tag.totalCount}</span>
              )}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

TagList.defaultProps = {
  label: 'Article tags',
};

TagList.propTypes = {
  /**
   * Any array list of tags can be passed in
  */
  tags: PropTypes.array.isRequired,
  /**
   * Accessible label text describing the tag list navigation element
  */
  label: PropTypes.string,
  /**
   * Text to be prepended before the tag list
  */
  prepend: PropTypes.string,
  /**
   * Variant classes used to apply different styles to tag list
  */
  variant: PropTypes.oneOf(['large']),
};

export default TagList;