import React from "react";
import { graphql } from "gatsby";

// Layout Imports
import Default from '../../../layouts/default';
import Seo from '../../../layouts/seo';

// Component Imports
import PageHeader from '../../../components/page-header';
import TagList from '../../../components/tag-list';
import Section from '../../../components/section';

// Asset Imports
import TagImage from '../../../assets/images/content/collecting.svg';

const TagsPage = ({
  data: {allMarkdownRemark: { group }},
}) => (
  <Default>
      <Seo
        title='All Tags'
        description='Showing a listing of all article tags'
      />

      <PageHeader
				heading='All Tags'
				imageSource={TagImage}
				description={`Viewing total ${group.length} tags applied to articles`}
        breadcrumbs={[
          {name: 'Home', url: '/'},
          {name: 'Articles', url: '/articles'},
        ]}
			/>

      <Section
        label='Tag listing'
        variants={['padded']}
      >
        {group && (
          <TagList
            tags={group}
            count
            variant='large'
          />
        )}
      </Section>
    </Default>
);

export default TagsPage;

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000,
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
